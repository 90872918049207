import React, { useMemo } from "react";
import { Route, Routes } from "react-router-dom";

import Footer from "./navbars/Footer";
import Header from "./navbars/Header";

import ScrollToTop from "./scrollToTop";

import { capabilitiesData } from "./capabilities/CapabilitiesData";
import { companyData } from "./company/CompanyData";
import { Facilities } from "./company/Facilities";
import { Observatories } from "./company/Observatories";
import { TeamPage } from "./company/TeamPage";
import { StandardPage } from "./components/StandardPage";
import { ContactPage } from "./contact/ContactPage";
import { navLinks } from "./navData";
import { NewsComponent } from "./news/NewsComponent";
import { newsMap } from "./news/newsData";
import { productsData } from "./products/ProductsData";
import { TKOFooter } from "./products/TKO";
import SplashPage from "./splash/Container";

const AppShell = () => {
  const pageMapping = useMemo(
    () => ({
      capabilities: {
        detecting: <StandardPage data={capabilitiesData.detecting} />,
        capturing: <StandardPage data={capabilitiesData.capturing} />,
        moving: <StandardPage data={capabilitiesData.moving} />,
        processing: <StandardPage data={capabilitiesData.processing} />,
      },
      products: {
        tko: (
          <>
            <StandardPage data={productsData.tko} />
            <TKOFooter />
          </>
        ),
        // accutilt: <StandardPage data={productsData.accutilt} />,
        theia: <StandardPage data={productsData.theia} wideImages={true} />,
      },
      company: {
        team: <TeamPage data={companyData.team} />,
        facilities: <Facilities data={companyData.facilities} />,
        observatories: <Observatories data={companyData.observatories} />,
      },
      news: {
        featured: <NewsComponent type={newsMap.featured} />,
        articles: <NewsComponent type={newsMap.articles} />,
        videos: <NewsComponent type={newsMap.videos} />,
        podcasts: <NewsComponent type={newsMap.podcasts} />,
        publicAppearances: <NewsComponent type={newsMap.publicAppearances} />,
      },
      contact: {
        index: <ContactPage />,
      },
    }),
    []
  );

  return (
    <>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<SplashPage />} />
        {Object.values(navLinks).map((pageData) => (
          <Route key={pageData.link} path={pageData.link}>
            {pageData.dropdowns ? (
              Object.values(pageData.dropdowns).map((dropdownData) => (
                <Route
                  key={pageData.name + dropdownData.link}
                  path={dropdownData.link}
                  element={pageMapping[pageData.link][dropdownData.link]}
                />
              ))
            ) : (
              <Route index element={pageMapping[pageData.link].index} />
            )}
          </Route>
        ))}
      </Routes>
      <Footer />
    </>
  );
};

export default AppShell;
