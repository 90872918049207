import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastProvider } from "./components/Toast";

import AppShell from "./AppShell";

const App = () => {
  return (
    <Router>
      <ToastProvider>
        <AppShell />
      </ToastProvider>
    </Router>
  );
};

export default App;
