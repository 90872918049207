import React from "react";
import "../../css/splash/quote.css";
import { QuoteData } from "./SplashData";

export const Quote = () => {
  return (
    <div className="quote-container">
      {QuoteData.map((section, index) => (
        <div key={index} className="quote-section">
          <h2 className="section-title">{section.title}</h2>

          <div className="section-content">
            <div className="text-container">
              <ul>
                {section.textContent.map((bullet, bulletIndex) => (
                  <li key={bulletIndex}>{bullet}</li>
                ))}
              </ul>
            </div>

            <div className="image-container">
              {section.href ? (
                <img
                  src={section.href}
                  alt={section.title}
                  className="section-image"
                />
              ) : (
                <div className="image-grid">
                  {section.hrefs.map((href, hrefIndex) => (
                    <img
                      key={hrefIndex}
                      src={href}
                      // alt={section.title}
                      alt={""}
                      className="section-image"
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
