import React, { useState } from "react";
import backgroundImage from "../../assets/nebulas/Nebula-01.png";
import "../../css/contact/contact.css";
import { useToast } from "../components/Toast";
import { sendMessage } from "../mailFunctions";

const CONTACT_REASONS = ["Sales", "Career", "Media", "Invest", "Other"];

export const ContactPage = () => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    subject: "",
    name: "",
    organization: "",
    email: "",
    phone: "",
    message: "",
    reasonForContact: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    // Required fields validation
    if (!formData.subject.trim()) newErrors.subject = "Subject is required";
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!formData.message.trim()) newErrors.message = "Message is required";
    if (!formData.reasonForContact)
      newErrors.reasonForContact = "Please select a reason for contact";

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      sendMessage(formData, toast);
      // Reset form after submission
      setFormData({
        subject: "",
        name: "",
        organization: "",
        email: "",
        phone: "",
        message: "",
        reasonForContact: "",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div
      className="contact-page"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="contact-container">
        <h1>Contact Us</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="subject">Subject *</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className={errors.subject ? "error" : ""}
            />
            {errors.subject && (
              <span className="error-message">{errors.subject}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={errors.name ? "error" : ""}
            />
            {errors.name && (
              <span className="error-message">{errors.name}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="organization">Organization</label>
            <input
              type="text"
              id="organization"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={errors.email ? "error" : ""}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>

          <div className="form-group contact-reason-select">
            <label htmlFor="reasonForContact">Reason for Contact *</label>
            <select
              id="reasonForContact"
              name="reasonForContact"
              value={formData.reasonForContact}
              onChange={handleChange}
              className={errors.reasonForContact ? "error" : ""}
            >
              <option value="">Select a reason</option>
              {CONTACT_REASONS.map((reason) => (
                <option key={reason} value={reason}>
                  {reason}
                </option>
              ))}
            </select>
            {errors.reasonForContact && (
              <span className="error-message">{errors.reasonForContact}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="message">Message *</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className={errors.message ? "error" : ""}
              rows="5"
            />
            {errors.message && (
              <span className="error-message">{errors.message}</span>
            )}
          </div>

          <button type="submit" className="submit-button">
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};
