import alex from "../../assets/company/team/alex.png";
import CompanyImage from "../../assets/company/team/company-image.jpeg";
import dawn from "../../assets/company/team/dawn.png";
import hayden from "../../assets/company/team/hayden.png";
import joel from "../../assets/company/team/joel-2.png";
import joel2 from "../../assets/company/team/joel.png";
import nichole from "../../assets/company/team/Nicole_Headshot_low_res.jpg";
import pete from "../../assets/company/team/pete.png";
import phil from "../../assets/company/team/phil.png";
import stan from "../../assets/company/team/stan.png";
import thibaud from "../../assets/company/team/thibaud.jpg";
import veger from "../../assets/company/team/veger.png";

import LabOutside from "../../assets/company/facilities/facilities-main.png";
import LabMural from "../../assets/company/facilities/lab1.png";
import LabTech1 from "../../assets/company/facilities/lab2.png";
import LabTech2 from "../../assets/company/facilities/lab3.png";

import WinerObservatory from "../../assets/company/observatories/observatories-2.jpg";
import SpringbrookObservatory from "../../assets/company/observatories/observatories-4.jpg";
import ObservatoriesMap from "../../assets/company/observatories/observatories-map.png";
import SierraObservatory from "../../assets/news/articles/removal_awareness.png";

import React from "react";
import { Link } from "react-router-dom";

export const companyData = {
  team: {
    title: "Leadership Team",
    leadership: [
      {
        name: "Joel Sercel, PhD.",
        description: "Founder & CEO",
        href: joel,
        in: "https://www.linkedin.com/in/joelsercel/",
      },
      {
        name: "Nicole Shumaker",
        description: "VP of Business Development",
        href: nichole,
        in: "https://www.linkedin.com/in/nicoleshumaker/",
      },
      {
        name: "Alex Pilmer ",
        description: "Chief Business Officer",
        href: alex,
        in: "https://www.linkedin.com/in/alexpilmer/",
      },
      {
        name: "Hayden Burgoyne, PhD.",
        description: "Director of Engineering",
        href: hayden,
        in: "https://www.linkedin.com/in/hayden-burgoyne/",
      },
      {
        name: "Philip Wahl",
        description: "Head of R&D Engineering",
        href: phil,
        in: "https://www.linkedin.com/in/philip-wahl/",
      },
      {
        name: "Thibaud Talon",
        description: "Chief of Mechatronics",
        href: thibaud,
        in: "https://www.linkedin.com/in/thibaudtalon/",
      },
    ],
    company: {
      title: "Meet Our Team",
      description:
        "TransAstra is revolutionizing space exploration through innovative technologies and solutions. Our team of experts is dedicated to making space accessible and sustainable for future generations.",
      href: CompanyImage,
    },
    board: {
      title: "Board Members",
      members: [
        {
          name: "Dawn Harms",
          href: dawn,
          in: "https://www.linkedin.com/in/dawn-harms-b217a43/",
          description:
            "Experienced aerospace executive with extensive  industry knowledge and trusted relationships.",
        },
        {
          name: "Dr. Stanley Love",
          href: stan,
          in: "https://www.linkedin.com/in/stan-love-8100bb8/",
          description:
            "Respected space traveler with extensive mission operations and development knowledge.",
        },
        {
          name: "Dr. Joel Sercel",
          href: joel2,
          in: "https://www.linkedin.com/in/joelsercel/",
          description:
            "World-renowned inventor, team leader, and entrepreneur.",
        },
        {
          name: "Paul Veger",
          href: veger,
          in: "https://www.linkedin.com/in/paulveger/",
          description:
            "Pioneering IT innovator, entrepreneur, and business leader.",
        },
        {
          name: "Pete Garretson",
          href: pete,
          in: "https://www.linkedin.com/in/petergarretson/",
          description:
            "Nationally recognized leader in defense strategy, national security, and space policy.",
        },
      ],
    },
  },
  facilities: {
    title: "The Hive",
    description: (
      <>
        The Hive is the 7,000-ft² facility we use for developing, integrating,
        and testing prototype assemblies and spaceflight hardware. This includes
        the <Link to="/capabilities/moving">Omnivore™</Link> thruster,{" "}
        <Link to="/capabilities/capturing">Capture Bag™</Link>,{" "}
        <Link to="/capabilities/detecting">Sutter™</Link> telescope systems, and
        the <Link to="/capabilities/moving">Worker Bee™</Link> flight system.
        Designed for agile engineering, The Hive is equipped with machine tools,
        rapid prototyping systems, large vacuum systems, optical benches, and
        extensive electrical test and development gear.
      </>
    ),
    outer: [LabMural, LabOutside],
    inner: [LabTech1, LabTech2],
    address: "The Hive, 13539 Desmond St, San Fernando, CA 91331",
  },
  observatories: {
    title: "Observatories",
    description:
      "Today we operate our Sutter telescope systems at observatories in the Arizona desert, the Sierra Nevada mountains in California, and Springbrook Research Obserbatory in Australia. Our 4th and 5th systems are under development for deployment soon after.",
    observatories: [
      {
        name: "Sierra Remote Observatory",
        href: SierraObservatory,
        location: "Yosemite, California",
      },
      {
        name: "Winer Observatory",
        href: WinerObservatory,
        location: "Tucson, Arizona",
      },
      {
        name: "Springbrook Research Observatory",
        href: SpringbrookObservatory,
        location: "Australia",
      },
    ],
    content: [],
    map: {
      title: "Observatory Locations",
      href: ObservatoriesMap,
    },
  },
};
