import React from "react";

import { Capabilities } from "./Capabilities";
import { Header } from "./Header";
import { Quote } from "./Quote";

const HeaderText = () => {
  return (
    <div className="splash-header-text-container">
      <div className="splash-header-text-content">
        <p>
          TransAstra is making asteroid mining a reality. To do this, we have
          developed four core capabilities:{" "}
          <span className="white">Detect</span>,{" "}
          <span className="white">Capture</span>,{" "}
          <span className="white">Move</span>, and{" "}
          <span className="white">Process</span>.
        </p>
        <p>
          Today, we are leveraging our unique{" "}
          <span className="white">Detect</span> capability to find satellites in
          Cislunar space for the Space Force. Later this year, we will deploy
          our <span className="white">Capture</span> bag on the International
          Space Station to enable orbital debris cleanup and promote Responsible
          Counterspace for the Space Force.
        </p>
      </div>
    </div>
  );
};

const SplashPage = () => {
  return (
    <div>
      <Header />
      <HeaderText />
      <Capabilities />
      <Quote />
    </div>
  );
};

export default SplashPage;
