import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/navbars/header-logo.png";
import "../../css/mainStyles/header.css";
import { navLinks } from "../navData";

const DesktopNav = ({ scrollWithOffset }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <nav className="desktop-nav">
      {Object.values(navLinks).map((section) => {
        const dropdowns = section.dropdowns
          ? Object.values(section.dropdowns)
          : null;
        return (
          <div className="nav-item" key={section.link}>
            <button
              className="nav-link"
              onClick={() => {
                if (location.pathname.includes("/" + section.link)) {
                  scrollWithOffset("top");
                } else {
                  navigate(
                    "/" +
                      section.link +
                      (dropdowns ? "/" + dropdowns[0].link : "")
                  );
                }
              }}
            >
              {section.name}
            </button>
            {dropdowns && (
              <div className="dropdown">
                {dropdowns.map((item) => (
                  <Link
                    key={item.link}
                    to={"/" + section.link + "/" + item.link}
                    className="dropdown-item"
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </nav>
  );
};

const MobileNav = ({ isOpen, scrollWithOffset, onClose }) => {
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState(null);

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
    scrollWithOffset("top");
  };

  return (
    <nav className={`mobile-nav ${isOpen ? "open" : ""}`}>
      {Object.values(navLinks).map((section) => {
        const dropdowns = section.dropdowns
          ? Object.values(section.dropdowns)
          : null;
        const isActive = openSection === section.link;

        return (
          <div className="mobile-nav-item" key={section.link}>
            <button
              className={`mobile-nav-link ${isActive ? "active" : ""}`}
              onClick={() => {
                if (dropdowns) {
                  setOpenSection(isActive ? null : section.link);
                } else {
                  handleNavigation("/" + section.link);
                }
              }}
            >
              {section.name}
              {dropdowns && (
                <span className={`arrow ${isActive ? "up" : "down"}`}>▼</span>
              )}
            </button>
            {dropdowns && (
              <div className={`mobile-dropdown ${isActive ? "open" : ""}`}>
                {dropdowns.map((item) => (
                  <Link
                    key={item.link}
                    to={"/" + section.link + "/" + item.link}
                    className="mobile-dropdown-item"
                    onClick={() => onClose()}
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </nav>
  );
};

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 1050);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    // Prevent scrolling when mobile menu is open
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isMobileMenuOpen]);

  const scrollWithOffset = (eleID) => {
    setIsMobileMenuOpen(false);
    setTimeout(() => {
      if (eleID === "top") {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      const yCoordinate =
        document.getElementById(eleID).getBoundingClientRect().top +
        window.pageYOffset;
      const navbar = document.getElementsByClassName("header")[0];
      const yOffset = -navbar.offsetHeight;
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    }, 75);
  };

  return (
    <>
      <header className={`header ${isMobileMenuOpen ? "nav-open" : ""}`}>
        <div className="header-content">
          <Link
            to="/"
            className="logo-link"
            onClick={() => {
              scrollWithOffset("top");
              setIsMobileMenuOpen(false);
            }}
          >
            <img src={logo} alt="TransAstra Logo" className="logo" />
          </Link>

          {isMobile && (
            <button
              className={`hamburger ${isMobileMenuOpen ? "open" : ""}`}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              aria-label="Toggle menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          )}

          {!isMobile && <DesktopNav scrollWithOffset={scrollWithOffset} />}
        </div>
      </header>

      {isMobile && (
        <>
          <MobileNav
            isOpen={isMobileMenuOpen}
            scrollWithOffset={scrollWithOffset}
            onClose={() => setIsMobileMenuOpen(false)}
          />
          {isMobileMenuOpen && (
            <button
              className="hamburger open"
              onClick={() => setIsMobileMenuOpen(false)}
              aria-label="Close menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          )}
        </>
      )}
    </>
  );
};

export default Header;
