import React from "react";
import "../../css/components/standardPage.css";

export const StandardPage = ({
  data,
  alternate = true,
  wideImages = false,
}) => {
  const { title, description, content } = data;

  return (
    <div className="standard-page">
      <div className="page-header">
        <h1 className="page-title">{title}</h1>
        <div className="page-description">{description}</div>
      </div>

      <div className="content-sections">
        {content.map((section, index) => (
          <div
            key={section.title + index}
            className={`content-section ${
              index % 2 === 0 || !alternate ? "text-left" : "text-right"
            } ${wideImages ? "wide-container" : ""}`}
          >
            <div className={`text-container ${wideImages ? "wide" : ""}`}>
              {section.title && (
                <h2 className="section-title">{section.title}</h2>
              )}
              {section.description && (
                <div className="section-description">{section.description}</div>
              )}
              {section.bullets && (
                <ul className="section-bullets">
                  {section.bullets.map((bullet, index) => (
                    <li key={index}>{bullet}</li>
                  ))}
                </ul>
              )}
            </div>
            <div
              className={`image-container ${wideImages ? "wide" : ""} ${
                section.link ? "pointer" : ""
              }`}
              onClick={() => {
                if (section.link) {
                  window.open(section.link, "_blank");
                }
              }}
            >
              <img
                src={section.href}
                alt={section.title}
                className="section-image"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
