import Operate from "../../assets/products/TKO/TKO-operate.gif";
import Setup from "../../assets/products/TKO/TKO-setup.png";
import Ship from "../../assets/products/TKO/TKO-ship.png";

import React from "react";

require("../../css/products/TKO.css");

export const TKOFooter = () => {
  return (
    <div className="tko-footer">
      <div className="tko-footer-item">
        <img src={Ship} alt="Ship" />
        <p>Easy to deliver</p>
      </div>
      <div className="tko-footer-item">
        <img src={Setup} alt="Setup" />
        <p>Fast to set up</p>
      </div>
      <div className="tko-footer-item">
        <img src={Operate} alt="Operate" />
        <p>Simple to operate</p>
      </div>
    </div>
  );
};
