import React, { createContext, useContext, useEffect, useState } from "react";
import "../../css/components/toast.css";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({ message: "", type: "", visible: false });

  useEffect(() => {
    if (toast.visible) {
      const timer = setTimeout(() => {
        setToast((prev) => ({ ...prev, visible: false }));
      }, 3000); // Hide after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [toast.visible]);

  const showToast = (message, type = "success") => {
    setToast({
      message,
      type,
      visible: true,
    });
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      {toast.visible && (
        <div className={`toast-container ${toast.type}`}>
          <div className="toast-content">
            <span className="toast-message">{toast.message}</span>
          </div>
        </div>
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const showToast = useContext(ToastContext);

  if (!showToast) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return {
    success: (message) => showToast(message, "success"),
    error: (message) => showToast(message, "error"),
  };
};
