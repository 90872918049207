import { StandardPage } from "../components/StandardPage";

import React from "react";

require("../../css/products/TKO.css");
require("../../css/company/observatories.css");

export const Observatories = ({ data }) => {
  return (
    <>
      <StandardPage data={data} />
      <div className="tko-footer no-padding-top">
        {data.observatories.map((observatory) => (
          <div key={observatory.name} className="tko-footer-item">
            <img src={observatory.href} alt={observatory.name} />
            <p>{observatory.location}</p>
          </div>
        ))}
      </div>
      <div className="observatory-map-container">
        <h2>{data.map.title}</h2>
        <div className="observatory-map-container-inner">
          <div className="observatory-map-legend">
            <div className="legend-item green">Currently operating</div>
            <div className="legend-item blue">
              In fabrication for 2025 deployment
            </div>
            <div className="legend-item yellow">Planned global network</div>
          </div>
          <div className="observatory-map">
            <img src={data.map.href} alt={data.map.title} />
          </div>
        </div>
      </div>
    </>
  );
};
