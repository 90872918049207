import SutterGround from "../../assets/capabilities/detect/detect-1.jpg";
import SutterSpace from "../../assets/capabilities/detect/detect-2.png";

import Capture1 from "../../assets/capabilities/capture/capture-1.png";
import Capture2 from "../../assets/capabilities/capture/capture-2.png";
import Capture3 from "../../assets/capabilities/capture/capture-3.png";

import Move1 from "../../assets/capabilities/move/move-1.jpg";
import Move2 from "../../assets/capabilities/move/move-2.png";

import Processing1 from "../../assets/capabilities/process/process-1.png";

import React from "react";
import { Link } from "react-router-dom";

export const capabilitiesData = {
  detecting: {
    title: "Sutter™ Telescope Technology",
    description: (
      <>
        With our patented{" "}
        <Link className="link" to="/products/theia">
          Theia
        </Link>{" "}
        software, our Sutter telescopes use inexpensive off-the-shelf hardware
        to find and track faint objects in space.
      </>
    ),
    content: [
      {
        title: "Sutter Ground",
        description: (
          <>
            Our ground-based systems conduct nightly asteroid and spacecraft
            surveys and regularly report detections.{" "}
            <Link className="link" to="/products/tko">
              Sutter TKO
            </Link>{" "}
            will provide next-generation observation capability and close
            critical coverage gaps in ground-based SDA.
          </>
        ),
        href: SutterGround,
      },
      {
        title: "Sutter Space",
        description: (
          <>
            With breakthrough computational efficiency, Theia runs on small
            on-board computers and minimizes downlink data rates by transmitting
            only detections.
          </>
        ),
        href: SutterSpace,
      },
    ],
  },
  capturing: {
    title: "Capture Bag™",
    description: `
        Capture Bag offers the most affordable way to clean
        up orbital debris and satellites at the end of their
        life. Capture Bag is scalable and capable of working with objects of all
        shapes and sizes. Later, we will use it to capture and mine asteroids.
      `,
    content: [
      {
        title: "Payloads for Host Spacecraft",
        description: `
            Capture Bag — using rip-stop inflatable bags — encloses and secures
            uncooperative targets, including cubesats, larger satellites,
            or even entire rocket bodies.
          `,
        href: Capture1,
      },
      {
        title: "Cutting-Edge Remediation Approach",
        description: `
            We can turn orbital debris into valuable resources. Recycling costs 6 times less than 
            "de-orbiting" debris, uses 80% less propellant, and clears a given orbit 40% faster.
          `,
        href: Capture2,
      },
      {
        title: "Honey Bee™ Asteroid Mining Vehicle",
        description: `Capture Bag is a key component to our future asteroid mining plans.
          It will capture asteroids the size of houses, which can contain as much water as a swimming pool.`,
        href: Capture3,
      },
    ],
  },
  moving: {
    title: "Worker Bee™ Orbital Transport Vehicle",
    description: `
      Worker Bee is a radical approach to in-space mobility. Worker Bee's
      powerful and practical propulsion system uses simple concentrated
      sunlight for power.
    `,
    content: [
      {
        title: "Omnivore",
        description: `
          Omnivore solar thermal propulsion rivals the performance of nuclear
          rockets at a fraction of the cost, without the major safety issues.

        `,
        href: Move1,
      },
      {
        title: "Worker Bee Upper Stage",
        description: `
          Worker Bee hydrogen upper stage unlocks fast deliveries to MEO, GEO,
          lunar orbits and beyond.
        `,
        href: Move2,
      },
      //   {
      //     title: "Worker Bee Fleets",
      //     description: `
      //     Deliver a constellation in days, not months. Reduce or eliminate the
      //       most expensive part of a satellite - the main propulsion system - and
      //       get your constellation into its operational phase months or years
      //       ahead of schedule.
      //       `,
      //     href: Move3,
      //   },
    ],
  },
  processing: {
    title: "Optical Mining™ ",
    description: `
      Earth-based mining techniques will not work on asteroids, but Optical
      Mining will. Optical Mining uses focused sunlight to efficiently extract
      resources from asteroids and space debris.
    `,
    content: [
      {
        title: "Apis™ Mission Architecture",
        description: `
          Our NASA-funded and reviewed mission studies show a near-term $100B
          commercial potential for asteroid mining.
        `,
        href: Processing1,
      },
      {
        title: "TransAstra Demonstration",
        description: (
          <>
            TransAstra demonstrated Optical Mining of a synthetic asteroid in{" "}
            <a
              target="_blank"
              className="link"
              href="https://www.youtube.com/watch?v=e5GHsIJCS4M"
            >
              White Sands, New Mexico
            </a>{" "}
            in 2015.
          </>
        ),
        // href: Processing2,
        href: "https://i3.ytimg.com/vi/e5GHsIJCS4M/mqdefault.jpg",
      },
    ],
  },
};
