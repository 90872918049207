import Theia1 from "../../assets/products/theia/theia-1.png";
import Theia2 from "../../assets/products/theia/theia-2.png";
import Theia3 from "../../assets/products/theia/theia-3.png";

import AccuTilt from "../../assets/products/AccuTilt/AccuTilt-main.png";
import TKOWhite from "../../assets/products/TKO/Sutter-TKO-line-white.png";
import TKOSecond from "../../assets/products/TKO/TKO-night-3png.png";

import React from "react";
import { Link } from "react-router-dom";

export const productsData = {
  tko: {
    title: "Sutter Turn Key Observatory (TKO)",
    description: (
      <div className="multi-line-description">
        <p>
          Sutter TKO is a modular, rapidly deployable sky survey system for
          space domain awareness in MEO, GEO and beyond. TransAstra's Sutter
          Ground Network consists of three high performance telescope systems in
          CA, AZ, and AUS that outperform every other commercial provider in
          cislunar observations.
        </p>
        <p>
          TKO operates at the customer's chosen location, eliminating the need
          for an observatory.
        </p>
        <p>
          TransAstra's Sutter Turn Key Observatory (Sutter TKO) delivers
          unparalleled performance in detecting all sky moving objects at a
          fraction of the cost of traditional telescopes and observatories.
        </p>
        <p>
          When equipped with{" "}
          <Link className="link" to="/products/theia">
            Theia
          </Link>{" "}
          software, TKO becomes 100s of times more sensitive and transforms into
          a fully autonomous observe-and-compute system.
        </p>
      </div>
    ),
    content: [
      {
        title: "",
        bullets: [
          "Six Celestron RASA telescopes with application optimized Theia software sensors and compute",
          "Weatherproof enclosure",
          "Pneumatically actuated to allow remote swapping between large FoV survey mode and co-boresighted mode",
          "Integrated climate control for compute thermal management (installed on-site)",
        ],
        href: TKOWhite,
      },
      {
        title: "",
        bullets: [
          // "Each TKO module houses 6 Celestron RASA 11 Telescopes",
          "Observatory enclosure opens and closes autonomously",
          "Computing systems for image processing",
          "High-speed global internet included",
          "Requires only electrical power and a relatively flat surface (solar array and battery as an option if power not available)",
        ],
        href: TKOSecond,
      },
    ],
  },
  accutilt: {
    title: "AccuTilt™",
    description: `AccuTilt is an intuitive tip-tilt mount that will save you time anguish
    and improve image quality. AccuTilt is designed for Celestron's RASA telescopes for
    precise focal plane parallelism, producing ultimate scientific imaging
    quality.`,
    content: [
      {
        title: "",
        bullets: [
          "Direct X/Y adjustment with micrometer precision for repeatable adjustments",
          "Easy to use orthogonal kinematic adjustment design developed via field testing",
          "Real-time tilt correction using software assistance",
          "Designed for ease of assembly — camera can be mounted and removed quickly",
          "Unobstructed access to RASA collimation adjustment screws",
        ],
        href: AccuTilt,
      },
    ],
  },
  theia: {
    title: "Theia",
    description: "",
    content: [
      {
        title: "",
        description: `Our patented Theia algorithm enhances a telescope's
          ability to detect faint moving objects, outperforming conventional
          track-before-detect methods by 100 times or more.`,
        href: Theia1,
      },
      {
        title: "",
        description: `With no prior knowledge of an object's trajectory, Theia detects
          spacecraft or asteroids that would otherwise be invisible.`,
        href: Theia2,
      },
      {
        title: "",
        description: (
          <>
            Our <Link to="/capabilities/detecting">Sutter technology</Link>{" "}
            boosts the performance of various Space Domain Awareness (SDA)
            systems, including ground telescopes, star trackers, or specialized
            space telescopes. We have run the software in space and increased
            space telescope performance by more than 10X.
          </>
        ),
        href: Theia3,
      },
    ],
  },
};
