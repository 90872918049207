import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../css/splash/capabilities.css";
import useSwipe from "../hooks/useSwipe";
import { CapabilitiesData } from "./SplashData";

export const Capabilities = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleCardClick = (index) => {
    if (isAnimating || index === activeIndex) return;
    setIsAnimating(true);
    setActiveIndex(index);
  };

  const handleDotClick = (index) => {
    if (isAnimating || index === activeIndex) return;
    setIsAnimating(true);
    setActiveIndex(index);
  };

  const handleSwipeLeft = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setActiveIndex((prevIndex) => (prevIndex + 1) % CapabilitiesData.length);
  };

  const handleSwipeRight = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? CapabilitiesData.length - 1 : prevIndex - 1
    );
  };

  const swipeHandlers = useSwipe(handleSwipeLeft, handleSwipeRight);

  const getVisibleCards = () => {
    const cards = [];
    const totalCards = CapabilitiesData.length;

    // Previous card
    const prevIndex = (activeIndex - 1 + totalCards) % totalCards;
    cards.push({
      ...CapabilitiesData[prevIndex],
      position: "prev",
      className: "card prev",
    });

    // Active card
    cards.push({
      ...CapabilitiesData[activeIndex],
      position: "active",
      className: "card active",
    });

    // Next card
    const nextIndex = (activeIndex + 1) % totalCards;
    cards.push({
      ...CapabilitiesData[nextIndex],
      position: "next",
      className: "card next",
    });

    return cards;
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsAnimating(false), 500);
    return () => clearTimeout(timer);
  }, [activeIndex]);

  return (
    <section className="capabilities-section">
      <h2 className="section-title">Our Core Capabilities</h2>

      <div className="cards-container" {...swipeHandlers}>
        {getVisibleCards().map((card) => (
          <div
            key={card.id}
            className={card.className}
            onClick={() => handleCardClick(card.id)}
          >
            <div className="card-content">
              <span className="card-action">{card.action}</span>
              <h3 className="card-title">{card.title}</h3>
              <p className="card-description">{card.text}</p>
              <Link to={`/capabilities/${card.linkID}`} className="card-link">
                Learn More
              </Link>
            </div>
          </div>
        ))}
      </div>

      <div className="dot-indicators">
        {CapabilitiesData.map((_, index) => (
          <button
            key={index}
            className={`dot ${index === activeIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
            aria-label={`Go to capability ${index + 1}`}
          />
        ))}
      </div>
    </section>
  );
};
