import React, { useEffect, useState } from "react";
import "../../css/splash/header.css";

import header1 from "../../assets/splash/head-1.png";
import header2 from "../../assets/splash/head-2.png";
import header3 from "../../assets/splash/head-3.png";

const headerImages = [header1, header2, header3];

const HEADER_TEXT = "ENGINEERING\nTHE FUTURE\nOF SPACE";

const ImageComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTransitioning) return;

      setIsTransitioning(true);
      const nextIndex = (currentIndex + 1) % headerImages.length;

      // After transition completes, update the current index
      const timer = setTimeout(() => {
        setCurrentIndex(nextIndex);
        setIsTransitioning(false);
      }, 1000);

      return () => clearTimeout(timer);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex, isTransitioning]);

  const nextIndex = (currentIndex + 1) % headerImages.length;
  const prevIndex =
    (currentIndex - 1 + headerImages.length) % headerImages.length;

  return (
    <div className="img-container">
      <img
        src={headerImages[currentIndex]}
        alt="TransAstra Header"
        className={`primary-image ${isTransitioning ? "fadeOut" : ""}`}
      />
      <img
        src={headerImages[nextIndex]}
        alt="TransAstra Header"
        className={`secondary-image ${isTransitioning ? "fadeIn" : ""}`}
      />
      <img
        src={headerImages[prevIndex]}
        alt="TransAstra Header"
        className="secondary-image"
      />
    </div>
  );
};

export const Header = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const scrollToBottom = () => {
    const headerElement = document.getElementById("splash-header");
    const navbarElement = document.getElementsByClassName("header")[0];
    if (headerElement && navbarElement) {
      const headerBottom = headerElement.offsetTop + headerElement.offsetHeight;
      const navbarHeight = navbarElement.offsetHeight;
      window.scrollTo({
        top: headerBottom - navbarHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="splash-header" id="splash-header">
      <ImageComponent />

      <div className="content-wrapper">
        <h1>{HEADER_TEXT}</h1>
      </div>

      {!isMobile && (
        <button
          className="scroll-arrow"
          onClick={scrollToBottom}
          aria-label="Scroll to content"
        >
          <span className="arrow-icon"></span>
        </button>
      )}

      <div className="gradient-overlay"></div>
    </div>
  );
};
