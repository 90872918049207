import React from "react";
import { newsData, newsTypes } from "./newsData";

require("../../css/news/news.css");

export const NewsComponent = ({ type }) => {
  const sortedData = newsData[type].sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  return (
    <div id="news-component" className="news-page md-font">
      <h1 className="news-title xl-font">{newsTypes[type]}</h1>

      <div className="news-container">
        {sortedData.map((data) => {
          return (
            <div key={data.title}>
              <a target="_blank" href={data.link}>
                <div className="img-container">
                  <img src={data.href}></img>
                </div>
              </a>
              <a href={data.link}>
                <h2>{data.title}</h2>
              </a>
              <p>{data.date}</p>
              {data.location && <p>{data.location}</p>}
              {data.type && <p>{data.type}</p>}
            </div>
          );
        })}
      </div>
    </div>
  );
};
