import React from "react";
import "../../css/company/team.css";

const LeadershipCard = ({ member }) => (
  <div className="leadership-card">
    <div className="member-image">
      <img src={member.href} alt={member.name} />
    </div>
    <div className="member-info">
      <h3>{member.name}</h3>
      <p>{member.description}</p>
      <a
        href={member.in}
        target="_blank"
        rel="noopener noreferrer"
        className="linkedin-link"
      >
        LinkedIn
      </a>
    </div>
  </div>
);

const BoardCard = ({ member }) => (
  <div className="board-card">
    <div className="member-image">
      <img src={member.href} alt={member.name} />
    </div>
    <div className="member-info">
      <h3>{member.name}</h3>
      <p>{member.description}</p>
      <a
        href={member.in}
        target="_blank"
        rel="noopener noreferrer"
        className="linkedin-link"
      >
        LinkedIn
      </a>
    </div>
  </div>
);

const CompanySection = ({ data }) => (
  <div className="company-section">
    <div className="company-content">
      <div className="company-text">
        <h2>{data.title}</h2>
        <p>{data.description}</p>
      </div>
      {data.href && (
        <div className="company-image">
          <img src={data.href} alt="Company" />
        </div>
      )}
    </div>
  </div>
);

export const TeamPage = ({ data }) => {
  return (
    <div className="team-page">
      <section className="leadership-section">
        <h2 className="section-title">{data.title}</h2>
        <div className="leadership-grid">
          {data.leadership.map((member, index) => (
            <LeadershipCard key={index} member={member} />
          ))}
        </div>
      </section>

      <CompanySection data={data.company} />

      <section className="board-section">
        <h2 className="section-title">{data.board.title}</h2>
        <div className="board-grid">
          {data.board.members.map((member, index) => (
            <BoardCard key={index} member={member} />
          ))}
        </div>
      </section>
    </div>
  );
};
