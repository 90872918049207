export const navLinkMap = {
  Detect: "detecting",
  Move: "moving",
  Capture: "capturing",
  Process: "processing",
  "Turn Key Observatory": "tko",
  // AccuTilt: "accutilt",
  // accutilt: "AccuTilt",
  "Data Systems": "systems",
  Team: "team",
  Facilities: "facilities",
  Observatories: "observatories",
  News: "news",
  Featured: "featured",
  Articles: "articles",
  Videos: "videos",
  Theia: "theia",
  detecting: "Detect",
  moving: "Move",
  capturing: "Capture",
  processing: "Process",
  tko: "Turn Key Observatory",
  systems: "Data Systems",
  team: "Team",
  facilities: "Facilities",
  observatories: "Observatories",
  news: "News",
  featured: "Featured",
  articles: "Articles",
  videos: "Videos",
  theia: "Theia",
  Podcasts: "podcasts",
  podcasts: "Podcasts",
  "Public Appearances": "publicAppearances",
  publicAppearances: "Public Appearances",
};

export const navLinks = {
  capabilities: {
    name: "Capabilities",
    link: "capabilities",
    dropdowns: {
      detecting: { title: "Detect", link: "detecting" },
      capturing: { title: "Capture", link: "capturing" },
      moving: { title: "Move", link: "moving" },
      processing: { title: "Process", link: "processing" },
    },
  },
  products: {
    name: "Products",
    link: "products",
    dropdowns: {
      tko: { title: "Turn Key Observatory", link: "tko" },
      // accutilt: { title: "AccuTilt", link: "accutilt" },
      theia: { title: "Theia", link: "theia" },
    },
  },
  company: {
    name: "Company",
    link: "company",
    dropdowns: {
      team: { title: "Team", link: "team" },
      facilities: { title: "Facilities", link: "facilities" },
      observatories: { title: "Observatories", link: "observatories" },
    },
  },
  news: {
    name: "News",
    link: "news",
    optional: { title: "Events", link: "top" },
    dropdowns: {
      featured: { title: "Featured", link: "featured" },
      articles: { title: "Articles", link: "articles" },
      videos: { title: "Videos", link: "videos" },
      podcasts: { title: "Podcasts", link: "podcasts" },
      publicAppearances: {
        title: "Public Appearances",
        link: "publicAppearances",
      },
    },
  },
  contact: {
    name: "Contact Us",
    link: "contact",
    dropdowns: null,
  },
};
