import React from "react";
import logo from "../../assets/navbars/footer-logo.png";
import linkedIn from "../../assets/navbars/linkedin-logo.png";
import xLogo from "../../assets/navbars/x-logo.png";
import youtube from "../../assets/navbars/youtube-logo.png";
import "../../css/mainStyles/footer.css";

const socialLinks = [
  {
    name: "X",
    href: xLogo,
    url: "https://twitter.com/transastracorp?lang=en",
  },
  {
    name: "LinkedIn",
    href: linkedIn,
    url: "https://www.linkedin.com/company/trans-astronautica-corporation/",
  },
  {
    name: "YouTube",
    href: youtube,
    url: "https://www.youtube.com/@transastronauticacorporati5535",
  },
];

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-info">
          <div className="footer-logo">
            <img src={logo} alt="TransAstra Logo" />
          </div>
          <address className="footer-address">
            13539 Desmond St, Los Angeles, CA 91331
          </address>
          <div className="footer-address-link">
            <a
              target="_blank"
              href="https://www.google.com/maps/place/TransAstra+Corporation/@34.2759313,-118.4301327,17z/data=!3m1!4b1!4m6!3m5!1s0x80c293b1108bffff:0x903b5b20b165131d!8m2!3d34.2759269!4d-118.4275524!16s%2Fg%2F11fm_7hf4y?hl=en&entry=ttu&g_ep=EgoyMDI1MDMxMi4wIKXMDSoJLDEwMjExNDU1SAFQAw%3D%3D"
            >
              View Map
            </a>
          </div>
          <p className="footer-copyright">
            Copyright © 2017-{currentYear} Trans Astronautica Corporation. All
            rights reserved.
          </p>
        </div>

        <div className="footer-social">
          {socialLinks.map((social) => (
            <a
              key={social.name}
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
              aria-label={`Visit TransAstra on ${social.name}`}
            >
              <img src={social.href} alt={`${social.name} Icon`} />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
