import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import db from "./firebase";

export async function sendMessage(formData, toast) {
  console.log("Sending Email with data: ", formData);
  try {
    const docRef = await addDoc(collection(db, "emails"), {
      Subject: formData.subject,
      Name: formData.name,
      Organization: formData.organization,
      Email: formData.email,
      Phone: formData.phone,
      Message: formData.message,
      type: formData.reasonForContact,
      timestamp: serverTimestamp(),
    });
    console.log("Email written to database with ID: ", docRef.id);
    toast.success("Email Sent Successfully");
    return true;
  } catch (e) {
    console.error("Error adding document: ", e);
    toast.error("Server Error Sending Email");
    return false;
  }
}
