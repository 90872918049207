import hilldaleImage from "../../assets/news/appearances/hilldale.png";
import NiacVCTalk from "../../assets/news/appearances/NIAC-VC-talk.png";
import captureBagImage from "../../assets/news/articles/gold_rush.png";
import celestronImage from "../../assets/news/podcasts/cosmos_safari.png";

import futureOfTransport from "../../assets/news/appearances/futureOfTransport.png";
import hilldale from "../../assets/news/appearances/hilldale.png";
import hyper_space from "../../assets/news/appearances/hyper_space.png";
import siliconValley from "../../assets/news/appearances/siliconValley.png";
import smallSat from "../../assets/news/appearances/smallSat.png";
import SpaceCenterHouston from "../../assets/news/appearances/SpaceCenterHouston.png";
import spaceComm from "../../assets/news/appearances/spaceComm.png";
import spaceExpo from "../../assets/news/appearances/spaceExpo.png";
import spaceIndustrial from "../../assets/news/appearances/spaceIndustrial.png";
import SpaceIndustrialBase from "../../assets/news/appearances/SpaceIndustrialBase.png";
import spacesettlement from "../../assets/news/appearances/spacesettlement.png";

import cosmos_safari from "../../assets/news/podcasts/cosmos_safari.png";
// import Eliana_Sheriff from "../../assets/news/podcasts/Eliana_Sheriff.png";
// import fos from "../../assets/news/podcasts/fos.png";
import Futurati_Podcast from "../../assets/news/podcasts/Futurati_Podcast.png";
import futurized from "../../assets/news/podcasts/futurized.png";
import Newspace from "../../assets/news/podcasts/Newspace.png";
import Orbital_Mechanics from "../../assets/news/podcasts/Orbital_Mechanics.png";
import planetary_radio from "../../assets/news/podcasts/planetary_radio.png";
import space_show from "../../assets/news/podcasts/space_show.png";
import space_strategy from "../../assets/news/podcasts/space_strategy.png";
import Space_Things from "../../assets/news/podcasts/Space_Things.png";
// import TomStyleShow from "../../assets/news/podcasts/TomStyleShow.png";
import WSJ from "../../assets/news/podcasts/WSJ.png";
// import Your_Space_Journey from "../../assets/news/podcasts/Your_Space_Journey.png";

import maneuver2 from "../../assets/capabilities/move/move-2.png";
import dawn from "../../assets/company/team/dawn.png";
import Asteroid_mining from "../../assets/news/articles/Asteroid_mining.png";
import boldly_go from "../../assets/news/articles/boldly_go.png";
import capturBagMid from "../../assets/news/articles/capturBagMid.png";
import detection_tool from "../../assets/news/articles/detection_tool.png";
import gold_rush from "../../assets/news/articles/gold_rush.png";
import ground_systems from "../../assets/news/articles/ground-systems.jpg";
import Hive_Capture_Bag_Demo_Render from "../../assets/news/articles/Hive Capture Bag Demo Render.png";
import mineAsteroids from "../../assets/news/articles/mineAsteroids.png";
import mining_the_skies from "../../assets/news/articles/mining_the_skies.png";
import mother_lode from "../../assets/news/articles/mother_lode.png";
import Ominvore_Test_With_Plume from "../../assets/news/articles/Ominvore Test With Plume.png";
import pentagon from "../../assets/news/articles/pentagon.png";
import peterGarretson from "../../assets/news/articles/peterGarretson.png";
import removal_awareness from "../../assets/news/articles/removal_awareness.png";
import Space_Engines from "../../assets/news/articles/Space_Engines_7-31-23.png";
import space_mining from "../../assets/news/articles/space_mining.png";
import space_objects from "../../assets/news/articles/space_objects.png";
import space_rocks from "../../assets/news/articles/space_rocks.png";
import space_tugs from "../../assets/news/articles/space_tugs.png";
import spaceflight from "../../assets/news/articles/spaceflight.png";
import TKO_close_up_at_night from "../../assets/news/articles/TKO close up at night.png";
import TKO_for_IG_post from "../../assets/news/articles/TKO for IG post.png";
import USSF_contract from "../../assets/news/articles/USSF_contract.png";
import whosincharge from "../../assets/news/articles/whosincharge.png";
import theia3 from "../../assets/products/theia/theia-3.png";

export const newsTypes = {
  featured: "Featured",
  articles: "Articles",
  videos: "Videos",
  podcasts: "Podcasts",
  publicAppearances: "Public Appearances",
};

export const newsMap = {
  featured: "featured",
  articles: "articles",
  videos: "videos",
  podcasts: "podcasts",
  publicAppearances: "publicAppearances",
};

export const newsData = {
  featured: [
    {
      title: "Space Mining | Hilldale College Freedom Library",
      date: "November 12, 2024",
      location: "Hilldale College",
      type: "Public Appearance",
      href: hilldaleImage,
      link: "https://1a-1791.com/video/s8/2/v/K/g/Z/vKgZu.caa.mp4?u=0&b=0",
    },
    {
      title:
        "TransAstra: Revolutionizing Asteroid Mining and the Future of Space Economy | Presented by Celestron",
      date: "May 21, 2024",
      location: "Cosmo Safari",
      type: "Podcast",
      href: celestronImage,
      link: "https://www.youtube.com/watch?v=zG88rm2rDnA",
    },
    {
      title:
        "TransAstra Awarded First Defense Department Contract for Capture Bag, Following Success with NASA",
      date: "January 24, 2024",
      location: "Chronicle-Tribune",
      type: "Article",
      href: captureBagImage,
      link: "https://www.newswire.com/news/transastra-awarded-first-defense-department-contract-for-flytrap-22227603#:~:text=Through%20civil%2C%20commercial%2C%20and%20defense,Founder%20and%20CEO%20of%20TransAstra.",
    },
    {
      title: "NIAC Symposium VC Talk",
      date: "March 6, 2025",
      location: "NIAC Symposium",
      type: "Video",
      href: NiacVCTalk,
      link: "https://x.com/JoelSercel/status/1897709686084059256",
    },
    // {
    //   title:
    //     "TransAstra presentation on Asteroid Mining at 2020 Free Market Forum",
    //   date: "March 18, 2021",
    //   location: "Free Market Forum",
    //   type: "Video",
    //   href: "https://i3.ytimg.com/vi/Wp4C7F76YVE/maxresdefault.jpg",
    //   link: "https://www.youtube.com/watch?v=Wp4C7F76YVE",
    // },
  ],
  videos: [
    {
      href: "https://i3.ytimg.com/vi/zG88rm2rDnA/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=zG88rm2rDnA",
      title:
        "TransAstra: Revolutionizing Asteroid Mining and the Future of Space | Presented by Celestron",
      date: "May 21, 2024",
    },
    {
      href: "https://i3.ytimg.com/vi/tgWzpK-XC7c/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=tgWzpK-XC7c",
      title: "Why This Startup is Mining Asteroids",
      date: "March 4, 2024",
    },
    {
      href: "https://i3.ytimg.com/vi/wtJfTIuP5kc/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=wtJfTIuP5kc",
      title: "Worker Bee Constellation Deployment",
      date: "June 19, 2023",
    },
    {
      href: "https://i3.ytimg.com/vi/tKOL2Y5MjJM/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=tKOL2Y5MjJM",
      title: "Mini Bee Ground Optical Mining Demonstration",
      date: "October 5, 2022",
    },
    {
      href: "https://i3.ytimg.com/vi/ZuWJRMA2IvM/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=ZuWJRMA2IvM",
      title:
        "NIAC Symposium- Sutter Ultra: Breakthrough Space Telelscope for Prospecting Asteroids",
      date: "September 19, 2022",
    },
    {
      href: "https://i3.ytimg.com/vi/HNcrZdwrYgY/mqdefault.jpg",
      link: "https://www.youtube.com/watch?v=HNcrZdwrYgY",
      title: "Space Resources Roundtable",
      date: "June 26, 2022",
    },
    {
      href: "https://i3.ytimg.com/vi/IByXgnS7C1I/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=IByXgnS7C1I",
      title: "TransAstra's Sutter Telescope Technology",
      date: "June 14, 2022",
    },
    {
      href: "https://i3.ytimg.com/vi/qU3Z6rfDH_c/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=qU3Z6rfDH_c",
      title:
        "Humanity in Deep Space - A conversation on Space and Planetary Ethics",
      date: "September 16, 2021",
    },
    {
      href: "https://i3.ytimg.com/vi/3pndYPsmasY/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=3pndYPsmasY",
      title: "MiniBee Capture Bag Deployment Demostration",
      date: "May 13, 2021",
    },
    {
      href: "https://i3.ytimg.com/vi/Wp4C7F76YVE/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=Wp4C7F76YVE",
      title:
        "TransAstra presentation on Asteroid Mining at 2020 Free Market Forum",
      date: "March 18, 2021",
    },
    {
      href: "https://i3.ytimg.com/vi/UlwpqetwHRg/mqdefault.jpg",
      link: "https://www.youtube.com/watch?v=UlwpqetwHRg",
      title:
        "NIAC Phase 3: Mini Bee Prototype to Enable Demonstration Flight in LEO",
      date: "September 24, 2020",
    },
    {
      href: "https://i3.ytimg.com/vi/t3ZnIQOmdG4/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=t3ZnIQOmdG4",
      title:
        "Sun Flower Power System and Lunar Polar Propellant Mining Outpost",
      date: "March 10, 2020",
    },
    {
      href: "https://i3.ytimg.com/vi/X5GKz9XLh70/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=X5GKz9XLh70",
      title: "TransAstra's Optical Mining Techonology",
      date: "June 17, 2019",
    },
    {
      href: "https://i3.ytimg.com/vi/hLijoedlE2A/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=hLijoedlE2A",
      title: "TransAstra Linchpin to New Industrial Revolution",
      date: "June 17, 2019",
    },
    {
      href: "https://i3.ytimg.com/vi/e5GHsIJCS4M/mqdefault.jpg",
      link: "https://www.youtube.com/watch?v=e5GHsIJCS4M",
      title: "White Sands Asteroid Mining Tests",
      date: "November, 2015",
    },
  ],
  articles: [
    {
      link: "https://www.accesswire.com/954178/transastra-awarded-space-force-tacfi-contract-to-deploy-sutter-turnkey-observatory-tko",
      title:
        "TransAstra Awarded Space Force TACFI Contract to Deploy Sutter TurnKey Observatory (TKO)",
      date: "December 12, 2024",
      from: "AccessWire",
      href: TKO_for_IG_post,
    },
    {
      link: "https://www.newswire.com/news/transastra-appoints-space-industry-veteran-peter-garretson-to-board-of-22468501?_gl=1*11a69c6*_gcl_au*MTk0OTM0OTczMi4xNzMyNjQ2MTg4LjEwMTA3NTQzNDQuMTczMjY0NjIwNy4xNzMyNjQ2MjE0*_ga*MTg1NjA4NTgyOC4xNzE2Mzk2MjIw*_ga_MX6T5V36WQ*MTczMjY0NjE4Ny4yLjEuMTczMjY0NjYwNy42MC4wLjA.",
      title:
        "TransAstra Appoints Space Industry Veteran Peter Garretson to Board of Directors",
      date: "November 19, 2024",
      from: "NewsWire",
      href: peterGarretson,
    },
    {
      link: "https://www.marketwatch.com/press-release/transastra-achieves-milestone-with-first-ever-space-based-matched-filter-tracking-using-its-patented-sutter-technology-38e55c52",
      title:
        "TransAstra Achieves Milestone With First-Ever Space-Based Matched Filter Tracking Using Its Patented Sutter Technology",
      date: "September 27, 2024",
      from: "MarketWatch",
      href: TKO_close_up_at_night,
    },
    {
      link: "https://www.newswire.com/news/transastra-awarded-first-defense-department-contract-for-flytrap-22227603#:~:text=Through%20civil%2C%20commercial%2C%20and%20defense,Founder%20and%20CEO%20of%20TransAstra.",
      title:
        "TransAstra Awarded First Defense Department Contract for Capture Bag, Following Success With NASA",
      date: "January 24, 2024",
      from: "Chronicle-Tribune",
      href: gold_rush,
    },
    {
      link: "https://themessenger.com/tech/asteroid-mining-precious-metals-science-business",
      title:
        "Can We Mine Asteroids? Experts Explain Why It'll Happen Sooner Than You Think",
      date: "November 19, 2023",
      from: "The Messenger",
      href: mineAsteroids,
    },
    {
      link: "https://time.com/collection/best-inventions-2023/6327156/transastra-flytrap/",
      title: "Best Inventions of 2023",
      date: "October 24, 2023",
      from: "TIME",
      href: Hive_Capture_Bag_Demo_Render,
    },
    {
      link: "https://www.newsnationnow.com/space/company-nasa-space-junk/",
      title: "This company aims to help NASA clean up space junk",
      date: "September 13, 2023",
      from: "NewsNation",
      href: capturBagMid,
    },
    {
      link: "https://www.newswire.com/news/transastras-6th-patent-this-year-allows-for-tracking-of-dark-fast-22125800",
      title:
        "TransAstra's 6th Patent This Year Allows for Tracking of Dark, Fast-Moving Space Objects",
      date: "September 11, 2023",
      from: "NewsWire",
      href: theia3,
    },
    {
      link: "https://spacenews.com/transastra-claims-nasa-contract-for-debris-capture-bag/",
      title: "TransAstra claims NASA contract for debris capture bag",
      date: "August 20, 2023",
      from: "SpaceNews",
      href: capturBagMid,
    },
    {
      link: "https://www.nationaldefensemagazine.org/articles/2023/7/31/government-industry-explore-nuclear-solar-space-engines",
      title: "Government, Industry Explore Nuclear, Solar Space Engines",
      date: "July 31, 2023",
      from: "National Defense Magazine",
      href: Space_Engines,
    },
    {
      link: "https://news.satnews.com/2023/07/30/space-industry-veteran-brings-her-world-class-leadership-to-transastras-board-of-directors/",
      title:
        "Space industry veteran brings her world-class leadership to TransAstra’s Board of Directors",
      date: "July 30, 2023",
      from: "SatNews",
      href: dawn,
    },
    {
      link: "https://news.satnews.com/2023/05/19/transastra-receives-ussf-contract-to-explore-apps-for-omnivore-on-orbit-propulsion-pystem/",
      title:
        "TransAstra receives USSF contract to explore apps for Omnivore on-orbit propulsion system",
      date: "May 19, 2023",
      from: "SatNews",
      href: USSF_contract,
    },
    {
      link: "https://www.thedailybeast.com/space-startup-astroforge-wants-to-launch-an-asteroid-mining-revolution",
      title:
        "Could this Space Tech Startup Launch an Asteroid Mining Revolution?",
      date: "January 30, 2023",
      from: "Daily Beast",
      href: Asteroid_mining,
    },
    {
      link: "https://www.globenewswire.com/news-release/2023/01/19/2591746/0/en/TransAstra-Secures-Multiple-Awards-Furthering-Sutter-Telescope-Technology-Developments.html",
      title:
        "TransAstra Secures Multiple Awards, Furthering Sutter Telescope Technology Developments",
      date: "January 19, 2023",
      from: "GlobalNewswire",
      href: ground_systems,
    },
    {
      link: "https://www.space.com/space-mining-grinding-into-reality",
      title:
        "Space mining startups see a rich future on asteroids and the moon.",
      date: "January 12, 2023",
      from: "Space.com",
      href: space_mining,
    },
    {
      link: "https://payloadspace.com/transastra-receives-nasa-and-dod-awards-for-debris-removal-and-awareness/",
      title:
        "TransAstra Receives NASA and DoD Awards for Debris Removal and Awareness",
      date: "December 15, 2022",
      from: "Payload",
      href: removal_awareness,
    },
    {
      link: "https://OuterSpaceLand.com/008-TransAstra-Space-Age-Gold-Rush.html",
      title: "TransAstra and the Space Age Gold Rush",
      date: "October 27, 2022",
      from: "OuterSpaceLand",
      href: gold_rush,
    },
    {
      link: "https://www.alumni.caltech.edu/techer/mining-the-skies",
      title: "Mining the Skies",
      date: "October 11, 2022",
      from: "TECHER",
      href: mining_the_skies,
    },
    {
      link: "https://SpaceNews.com/transastra-celestron-telescopes/",
      title: "TransAstra and Celestron to modify telescopes for spaceflight",
      date: "September 27, 2022",
      from: "SpaceNews",
      href: spaceflight,
    },
    {
      link: "https://SpaceNews.com/transastra-and-slooh-to-offer-students-asteroid-detection-tool/",
      title: "TransAstra and Slooh to offer students asteroid detection tool",
      date: "August 09, 2022",
      from: "SpaceNews",
      href: detection_tool,
    },
    {
      link: "https://www.globenewswire.com/news-release/2022/07/19/2481778/0/en/Patent-Approved-for-Space-Propulsion-System-Poised-to-Transform-Orbital-Space-Logistics.html",
      title:
        "Patent Approved for Space Propulsion System Poised to Transform Orbital Space Logistics",
      date: "July 19, 2022",
      from: "GlobalNewswire",
      href: Ominvore_Test_With_Plume,
    },
    {
      link: "https://aviationweek.com/aerospace/commercial-space/sutter-telescope-tracks-small-dark-fast-moving-space-objects",
      title: "Sutter Telescope Tracks Small, Dark, Fast-Moving Space Objects",
      date: "July 08, 2022",
      from: "Aviation Week Network",
      href: space_objects,
    },
    {
      link: "https://www.businesswire.com/news/home/20220414005829/en/ReOrbit-and-TransAstra-Sign-Spacecraft-Development-and-Logistics-Contracts",
      title:
        "ReOrbit and TransAstra Sign Spacecraft Development and Logistics Contracts",
      date: "April 14, 2022",
      from: "businesswire",
      href: maneuver2,
    },
    {
      link: "https://spacesettlementprogress.com/2021/08/",
      title:
        "Where is the Mother Lode of Space Mining? The Moon or near-Earth asteroids?",
      date: "August 26, 2021",
      from: "Space Settlement Progress",
      href: mother_lode,
    },
    {
      link: "https://www.yahoo.com/video/silicon-valley-most-successful-incubator-100049345.html",
      title:
        "Silicon Valley's most successful incubator is doubling down on space tugs",
      date: "August 25, 2021",
      from: "yahoo!(QUARTZ)",
      href: space_tugs,
    },
    {
      link: "https://www.wsj.com/articles/pentagon-nasa-knock-down-barriers-impeding-joint-space-projects-11612187500?st=d54gkl8nzh75acn&reflink=desktopwebshare_permalink",
      title: "Pentagon, NASA Knock Down Barriers Impeding Joint Space Projects",
      date: "February 01, 2021",
      from: "Wall Street Journal",
      href: pentagon,
    },
    {
      link: "https://www.politico.com/news/2020/11/20/space-ethics-opinion-438526",
      title: "To boldly go . . . responsibly",
      date: "November 20, 2020",
      from: "POLITICO",
      href: boldly_go,
    },
    {
      link: "https://www.wsj.com/articles/whos-in-charge-of-outer-space-1495195097?st=ar23sbh0f25fhu4&reflink=desktopwebshare_permalink",
      title: "Who's in charge of Outer Space?",
      date: "May 19, 2017",
      from: "Wall Street Journal",
      href: whosincharge,
    },
    {
      link: "https://www.space.com/30582-asteroid-mining-water-propulsion.html",
      title:
        "Asteroid-Mining Plan Would Bake Water out of Bagged-Up Space Rocks",
      date: "September 18, 2015",
      from: "Space.com",
      href: space_rocks,
    },
  ],
  publicAppearances: [
    {
      title: "Joel Sercel speaks at NIAC Symposium on venture capital in space",
      date: "March 6, 2025",
      href: NiacVCTalk,
      link: "https://x.com/JoelSercel/status/1897709686084059256",
    },
    {
      href: SpaceCenterHouston,
      link: "https://www.youtube.com/watch?v=ecJRpAkLp08",
      title:
        "Engineering the Future of Space with TransAstra | Space Center Houston",
      date: "November 21, 2024",
    },
    {
      href: hilldale,
      link: "https://1a-1791.com/video/s8/2/v/K/g/Z/vKgZu.caa.mp4?u=0&b=0",
      title: "Space Mining | Hilldale College Freedom Library",
      date: "November 12, 2024",
    },
    {
      href: hyper_space,
      link: "https://vimeo.com/830289119/acd7a76627",
      title:
        "Hyperspace Challenge - From Brand to Infinity: Startup's Guide to Marketing",
      date: "May 25, 2023",
    },
    {
      href: SpaceIndustrialBase,
      link: "https://www.newspacenm.org/space-industrial-base/",
      title: "Joel Sercel speaks at State of the Space Industrial Base 2023",
      date: "May 18, 2023",
    },
    {
      href: spaceExpo,
      link: "https://www.spacetechexpo.com/",
      title:
        "Joel Sercel speaks at Space Tech Expo - Probing Emerging Technologies for Futer Space Exploration and Downstream Applications",
      date: "May 02, 2023",
    },
    {
      href: spaceComm,
      link: "https://www.spacecomexpo.com/",
      title: "Joel Sercel speaks at Space Com 2023 - Space Traffic Managment",
      date: "February 23, 2023",
    },
    {
      href: siliconValley,
      link: "https://2022.milsatshow.com/speakers/dr-joel-sercel/",
      title: "Joel Sercel speaks at Silicon Valley Space Week Symposium",
      date: "October 13, 2022",
    },
    {
      href: smallSat,
      link: "https://2022.smallsatshow.com/agenda/",
      title: "Small Sat Symposium",
      date: "February 09, 2022",
    },
    {
      href: spaceIndustrial,
      link: "http://aerospace.csis.org/wp-content/uploads/2020/07/State-of-the-Space-Industrial-Base-2020-Report_July-2020_FINAL.pdf",
      title: "State of the Space Industrial Base 2020",
      date: "May 05, 2020",
    },
    {
      href: spacesettlement,
      link: "https://spacesettlementsummit2019.nss.org/home/detailed-schedule/",
      title: "Space Settlement Summit",
      date: "November 14, 2019",
    },
    {
      href: futureOfTransport,
      link: "https://www.globenewswire.com/en/news-release/2018/04/26/1488369/6837/en/The-Wall-Street-Journal-Future-of-Everything-Festival-Presents-The-Future-of-Transportation-May-9th-in-New-York-City.html",
      title:
        "Wall St. Journal Future of Everything Festival: The Future of Transportation",
      date: "May 19, 2018",
    },
  ],
  podcasts: [
    {
      href: cosmos_safari,
      link: "https://www.cosmossafari.com/podcast/episode/7a1d2b2a/space-junk-recycling-asteroid-mining-and-engineering-the-future-of-space-presented-by-celestron",
      title:
        "TransAstra: Revolutionizing Asteroid Mining and the Future of Space Economy | Presented by Celestron",
      date: "May 21, 2024",
    },
    {
      href: "https://i3.ytimg.com/vi/sDajjtSRBJ4/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=sDajjtSRBJ4&t=1s",
      title:
        "Tom Style - Dr. Joel Sercel on Asteroid Gold Rush and TransAstra's Space Breakthroughs!",
      date: "November 21, 2023",
    },
    {
      href: "https://i3.ytimg.com/vi/H7You3GbsrI/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=H7You3GbsrI",
      title:
        "Your Space Journey Podcast - Asteroid Mining: Engineering the Future of Space",
      date: "June 19, 2023",
    },
    {
      href: "https://i3.ytimg.com/vi/icO3pu3Mcsk/maxresdefault.jpg",
      link: "https://www.youtube.com/watch?v=icO3pu3Mcsk",
      title: "Eliana Sheriff Youtube Podcast - Asteroid Mining",
      date: "November 29, 2022",
    },
    {
      href: futurized,
      link: "https://www.futurized.org/what-tech-breakthroughs-are-needed-for-asteroid-mining/",
      title:
        "Futurized Podcast - What Tech Breakthroughs Are Needed For Asteroid Mining?",
      date: "November 01, 2022",
    },
    {
      href: "https://i3.ytimg.com/vi/OD12SoVv7Ac/maxresdefault.jpg",
      link: "https://youtu.be/OD12SoVv7Ac",
      title: "Future of Space Podcast - Joel Sercel Interview",
      date: "October 27, 2022",
    },
    {
      href: Space_Things,
      link: "https://shows.acast.com/spaceandthings/episodes/stp113-how-to-use-an-asteroid-to-save-humanity-with-joel-ser",
      title:
        "Space and Things - How to Use and Asteroid to Save Humanity - with Joel Sercel",
      date: "October 26, 2022",
    },
    {
      href: Orbital_Mechanics,
      link: "https://theorbitalmechanics.com/show-notes/joel-sercel",
      title: "The Orbital Mechanics",
      date: "October 25, 2022",
    },
    {
      href: Newspace,
      link: "https://explorenewspace.com/blog/how-to-fuel-up-in-space",
      title: "Newspace Podcast - Fueling Up in Space",
      date: "October 12, 2022",
    },
    {
      href: space_show,
      link: "https://explorenewspace.com/blog/how-to-fuel-up-in-space",
      title: "The Space Show with Joel Sercel",
      date: "October 07, 2022",
    },
    {
      href: Futurati_Podcast,
      link: "https://podcasts.apple.com/au/podcast/ep-101-asteroid-mining-and-the-future-of-space-joel-sercel/id1541051806?i=1000577760954",
      title:
        "Futurati Podcast - Asteroid Mining and the Future of Space | Joel Sercel",
      date: "August 29, 2022",
    },
    {
      href: space_strategy,
      link: "https://www.afpc.org/news/podcasts/media/space-strategy-episode-12-there-is-gold-in-them-hills-a-deep-dive-into-asteroid-mining-space-logistics",
      title: `American Foreign Policy Council - "There is Gold in Them Hills!": A Deep Dive Into Asteroid Mining & Space Logistics`,
      date: "June 16, 2021",
    },
    {
      href: planetary_radio,
      link: "https://www.planetary.org/planetary-radio/1104-2020-joel-sercel",
      title:
        "Planetary Radio - A Return to Asteroid Mining and Digging Into Space Ethics with Joel Sercel",
      date: "November 4, 2020",
    },
    {
      href: WSJ,
      link: "https://www.wsj.com/podcasts/wsj-the-future-of-everything/the-future-of-everything-festival-when-humans-invade-space/6F506039-FCBB-49DB-B5D9-3C236B1FB191",
      title: "Wall St. Journal - The Future of Everything",
      date: "May 16, 2018",
    },
  ],
};
