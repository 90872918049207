import VacuumTanks from "../../assets/company/facilities/lab2.png";
import DarpaLogo from "../../assets/logos/darpa-logo.png";
import NasaLogo from "../../assets/logos/nasa-logo.png";
import NiacLogo from "../../assets/logos/niac-logo.png";
import SpaceForceLogo from "../../assets/logos/space-force-logo.png";
import YCLogo from "../../assets/logos/YC-logo-black.png";
import MattBellamy from "../../assets/splash/Matt-Bellamy.png";
import PatentPicture from "../../assets/splash/Patent-Picture.png";

const logos = [NasaLogo, NiacLogo, SpaceForceLogo, DarpaLogo, YCLogo];

export const CapabilitiesData = [
  {
    id: 0,
    action: "Detect",
    title: "Sutter™ Telescope Technology",
    text: "Our innovative Sutter Telescope system—named after Sutter's Mill in California, where gold was discovered in 1848—enables low-cost telescopes to detect asteroids and other dark, fast-moving objects in space.",
    linkID: "detecting",
  },
  {
    id: 1,
    action: "Capture",
    title: "Capture Bag™",
    text: "Capture Bag, a breakthrough innovation, will secure asteroids for mining and help clear Earth's orbits of dangerous debris.",
    linkID: "capturing",
  },
  {
    id: 2,
    action: "Move",
    title: "Worker Bee™ Powered by Omnivore™",
    text: "Our patented Omnivore propulsion system can utilize almost any volatile liquid, such as water, as a propellant, ensuring efficient spacecraft movement across vast distances in space.",
    linkID: "moving",
  },
  {
    id: 3,
    action: "Process",
    title: "Optical Mining™",
    text: "Optical Mining harnesses the Sun's power to extract resources, including water and precious metals, from asteroids. These methods will also play a key role in recycling orbital debris and promoting cleaner recycling processes on Earth. ",
    linkID: "processing",
  },
];

export const QuoteData = [
  {
    title: "World-Class Team",
    textContent: [
      "4 PhDs (Caltech, MIT) leading a team of 25",
      "Senior management with decades of experience building successful organizations",
      "TS/SCI Facility Clearance Level",
    ],
    href: MattBellamy,
  },
  {
    title: "State-of-the-Art Facilities",
    textContent: [
      "7,000-ft² lab",
      "Capture Bag™ test bed",
      "Two large vacuum systems",
      "Optics lab",
    ],
    href: VacuumTanks,
  },
  {
    title: "First-in-Class IP Portfolio",
    textContent: [
      "15 issued patents",
      "Patents cover Detect, Capture, Move, and Process",
      "~10 additional patents pending",
    ],
    href: PatentPicture,
  },
  {
    title: "Sales Traction & Financial Success",
    textContent: [
      "Multiple NASA and Space Force contracts for non-dilutive funding",
      "Revenue growth for three consecutive years",
      "Initial JCO SDA operations contracts for Cislunar activities",
      "Seed stage company, Y Combinator graduate (S21)",
    ],
    hrefs: logos,
  },
];
