import React from "react";
import "../../css/company/facilities.css";
import { companyData } from "./CompanyData";

export const Facilities = () => {
  const { facilities } = companyData;

  return (
    <div className="facilities-page">
      <h1 className="facilities-title">{facilities.title}</h1>

      <div className="outer-images">
        {facilities.outer.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image} alt={`The Hive Exterior ${index + 1}`} />
          </div>
        ))}
      </div>

      <div className="facilities-description">{facilities.description}</div>

      <div className="inner-images">
        {facilities.inner.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image} alt={`The Hive Interior ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* <div className="facilities-address">{facilities.address}</div> */}
    </div>
  );
};
